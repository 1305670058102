import NextLink from 'next/link'
import React, { FC } from 'react'

import { Box, Button, CircularProgress, Container } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import Link from '~/components/shared/Link'
import Text, { TextProps } from '~/components/shared/Text'

import { useDesktop, useMobile } from '~/hooks/responsive'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      link: {
        '&:hover': {
          color: theme.palette.primary.main
        }
      }
    }),
  { name: 'SectionLink' }
)

type SectionLinkProps = {
  linkHref?: string
  linkText?: string | JSX.Element
}

const SectionLink: FC<SectionLinkProps> = ({ linkText, linkHref = '/' }) => {
  const classes = useStyles()

  return (
    <Text color="textPrimary" fontFamily="Prompt, sans-serif" fontSize={14}>
      <NextLink href={linkHref} passHref>
        <Link
          href={linkHref}
          color="textPrimary"
          className={classes.link}
          prefetch={false}
        >
          {linkText || (
            <Box
              component="span"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={{ xs: 4, sm: 0 }}
            >
              View all <ChevronRightIcon />
            </Box>
          )}
        </Link>
      </NextLink>
    </Text>
  )
}

export type SectionProps = {
  title?: string
  displayLink?: boolean
  linkHref?: string
  linkText?: string | JSX.Element
  empty?: boolean
  emptyLink?: string
  emptyText?: string
  emptyButtonText?: string
  unmountOnEmpty?: boolean
  loading?: boolean
  titleProps?: Omit<TextProps, 'theme'>
  hideTitleBlock?: boolean
}

const Section: FC<SectionProps & BoxProps> = ({
  children,
  title,
  displayLink,
  linkText,
  linkHref = '/',
  empty,
  emptyLink,
  emptyText,
  emptyButtonText,
  unmountOnEmpty,
  loading,
  titleProps,
  hideTitleBlock,
  ...props
}) => {
  const isMobile = useMobile()
  const isDesktop = useDesktop()

  if (unmountOnEmpty && empty) return null

  const showDesktopLink = !empty && displayLink && isDesktop
  const showMobileLink = !empty && displayLink && isMobile

  return (
    <Box
      pt={6}
      pb={{ xs: 5, md: 9 }}
      component="section"
      width="100%"
      {...props}
    >
      <Container maxWidth="xl">
        {!hideTitleBlock && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            {title && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Text
                  variant="h2"
                  component="h2"
                  my={0}
                  fontSize={30}
                  fontWeight={500}
                  fontFamily="Prompt, sans-serif"
                  lineHeight="1.5"
                  {...titleProps}
                >
                  {title}
                </Text>

                {loading && (
                  <CircularProgress style={{ marginLeft: '8px' }} size="20px" />
                )}
              </Box>
            )}

            {showDesktopLink && (
              <SectionLink linkHref={linkHref} linkText={linkText} />
            )}
          </Box>
        )}

        {empty && (
          <Box p={2.5}>
            {emptyText && <Text mb={4}>{emptyText}</Text>}

            {emptyButtonText && emptyLink && (
              <NextLink passHref href={emptyLink} prefetch={false}>
                <Button variant="contained" color="primary">
                  {emptyButtonText}
                </Button>
              </NextLink>
            )}
          </Box>
        )}

        {!empty && children}

        {showMobileLink && (
          <SectionLink linkHref={linkHref} linkText={linkText} />
        )}
      </Container>
    </Box>
  )
}

export default Section
